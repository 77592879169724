import React from "react"
import Header from "../components/header"
import About from "../components/about"
import Repos from "../components/repos"
import CodigoFacilito from "../components/codigofacilito"
import EdNav from "../components/education-nav"
import Maintenance from "../components/maintenance"

export default () => <div>
  {/* <Header />
  <About />
  <EdNav />
  <Repos />
  <CodigoFacilito /> */}
  <Maintenance />
</div>
